@font-face { font-family: "tt_commonsdemibold"; src: url("../fonts/ttcommons-demibold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-demibold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonslight"; src: url("../fonts/ttcommons-light-webfont.woff2") format("woff2"), url("../fonts/ttcommons-light-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsmedium"; src: url("../fonts/ttcommons-medium-webfont.woff2") format("woff2"), url("../fonts/ttcommons-medium-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsblack"; src: url("../fonts/ttcommons-black-webfont.woff2") format("woff2"), url("../fonts/ttcommons-black-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commons_boldbold"; src: url("../fonts/ttcommons-bold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-bold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsextrabold"; src: url("../fonts/ttcommons-extrabold-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extrabold-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsextralight"; src: url("../fonts/ttcommons-extralight-webfont.woff2") format("woff2"), url("../fonts/ttcommons-extralight-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsregular"; src: url("../fonts/ttcommons-regular-webfont.woff2") format("woff2"), url("../fonts/ttcommons-regular-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: "tt_commonsthin"; src: url("../fonts/ttcommons-thin-webfont.woff2") format("woff2"), url("../fonts/ttcommons-thin-webfont.woff") format("woff"); font-weight: normal; font-style: normal; }

body {
	min-height: 100vh;
	height: 100%;
	font-family: "tt_commonsregular";
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.btn {
		font-family: "tt_commonsdemibold";
		font-size: 16px;
		box-shadow: none !important;
		text-transform: none;
		padding: 18px 35px 16px 35px;
		border-radius: 50px;
		transition: all 0.5s;

		&.btn-default {
			border: 2px solid @color1;
			color: #fff;
			background: @color1;

			&:hover {
				border-color: darken(@color1, 10%);
				background: darken(@color1, 10%);
			}
		}

		&.btn-border {
			color: #000;
			background: #fff;
			border: 2px solid @color1;

			&:hover {
				border-color: darken(@color1, 10%);
			}
		}

		&.btn-small {
			padding: 10px 20px 8px 20px;
			font-size: 14px;
		}
	}

	&.no-scroll {
		overflow: hidden;
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
}

@color1: #0092D7;

.return-to-top {
	position: fixed;
	z-index: 333;
	bottom: 15px;
	left: 15px;
	transition: all 0.3s;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s;
	visibility: hidden;
	background: darken(@color1, 10%);
	opacity: 0;

	img, svg {
		height: 45%;
		width: 45%;
	}

	svg {
		fill: #fff;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&:hover {
		background: darken(@color1, 20%);
	}
}

.btn-fixed {
	position: fixed;
	z-index: 333;
	top: 50%;
	right: 0;
	background: darken(@color1, 10%) !important;
	border-color: darken(@color1, 10%) !important;
	transform: translateY(-50%);
	border-radius: 50px 0 0 50px !important;

	&:hover {
		background: darken(@color1, 20%) !important;
		border-color: darken(@color1, 20%) !important;
	}
}

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.static-text-wrapper {
	h1, h2, h3, h4, h5, h6, p, li {
		color: #000;

		b, strong {
			font-family: 'tt_commonsdemibold';
		}
	}

	h1 {
		font-size: 50px;
	}

	h2 {
		font-size: 42px;
	}

	h3 {
		font-size: 36px;
	}

	h4 {
		font-size: 30px;
	}

	h5 {
		font-size: 24px;
	}

	h6 {
		font-size: 21px;
	}

	p, li {
		max-width: 1000px;
		font-size: 16px;
		font-family: 'tt_commonslight';

		b, strong {
			font-family: 'tt_commonsdemibold';
			color: @color1;
		}
	}

	a {
		color: @color1;
		text-decoration: underline;
	}

	ul, ol {
		padding-left: 17px;
		list-style: none;

		li {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 7px;
				left: -17px;
				height: 6px;
				width: 6px;
				border-radius: 50%;
				background: @color1;
			}

			& + li {
				margin-top: 8px;
			}
		}
	}

	img {
		max-width: 100%;
		border-radius: 3px;
	}

	table {
		tr {
			td {
				color: #000;
				font-size: 16px;
				border: none;
				transition: all 0.5s;

				b, strong {
					font-family: 'tt_commons_boldbold';
				}

				&:first-child {
					border-radius: 8px 0 0 8px;
				}

				&:last-child {
					border-radius: 0 8px 8px 0;
				}
			}

			&:hover {
				td {
					background: @color1;
					color: #fff;
				}
			}
		}
	}
}

.news-flexbox {
	margin-top: -70px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.news-service {
		width: 44%;
		margin-top: 70px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.service-image {
			width: 100%;
			padding-top: 60%;
			border-radius: 3px;
			margin-bottom: 25px;
			overflow: hidden;
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				transition: all 0.5s;
			}

			.image-cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 100%;
				background: @color1;
				opacity: 0.2;
				transition: all 0.5s;
			}
		}

		.name {
			font-size: 28px;
			font-family: 'tt_commonsmedium';
			line-height: 31px;
			color: #000;
			transition: all 0.5s;
		}

		.date {
			margin-top: 15px;
			font-size: 12px;
			font-family: 'tt_commonsmedium';
			color: #444;
		}

		.description {
			margin-top: 20px;
			font-family: 'tt_commonslight';
			font-size: 16px;
			color: #000;
		}

		.btn-more {
			margin-top: 40px;
			font-family: "tt_commonsdemibold";
			font-size: 14px;
			color: @color1;
		}

		&:hover {
			.service-image {
				img {
					transform: scale(1.1);
				}

				.image-cover {
					width: 100%;
				}
			}

			.name {
				color: @color1;
			}
		}
	}
}

.btn-more-products-wrapper {
	margin-top: 80px;
	text-align: center;

	.more-products-counter {
		margin-bottom: 35px;

		.number {
			font-size: 12px;
			color: #000;
			margin-bottom: 10px;
		}

		.line {
			margin: 0 auto;
			width: 145px;
			height: 4px;
			border-radius: 10px;
			background: #ddd;
			position: relative;

			.line-inner {
				border-radius: 10px;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background: @color1;
			}
		}
	}
}

.input-service {
	margin-top: 20px;
	display: inline-block;
	width: 100%;

	label {
		text-align: center;
		display: block;
		font-size: 16px;
		font-family: 'tt_commonsmedium';
		color: #000;
		margin: 0;

		small {
			font-size: 12px;
			color: #888;
		}

		& + input, & + textarea, & + .select-wrapper {
			margin-top: 10px;
		}
	}

	input {
		height: 45px;
		min-width: 95%;
		width: 100%;
		border: 1.5px solid #ccc;
		padding-top: 4px;
		border-radius: 20px;
		text-align: center;
		padding-right: 10px;
		font-size: 16px;
		color: #000;
		transition: all 0.5s;
		outline: none;

		&:focus {
			border-color: @color1;
		}
	}

	textarea {
		height: 100px;
		width: 100%;
		border: 1.5px solid #ccc;
		padding-top: 4px;
		border-radius: 50px;
		padding-left: 10px;
		text-align: center;
		padding-right: 10px;
		font-size: 16px;
		color: #000;
	}

	&.service-alert {
		label {
			color: #dc3545;
		}

		input {
			border-color: #dc3545;
		}
	}

	.input-alert {
		margin-top: 4px;
		font-size: 10px;
		font-weight: 600;
		color: #dc3545;
		text-align: center;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.35;
	}
}

.select-wrapper {
	position: relative;
	border-radius: 20px;
	overflow: hidden;

	select, .select2-selection--single {
		cursor: pointer;
		padding: 8px 15px 6px 15px;
		border: 1.5px solid #ccc;
		text-align: center;
		border-radius: 20px !important;
		background: #fff;
		height: auto;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		outline: none;
		display: block;
		transition: all 0.5s;

		&:focus {
			border-color: @color1;
		}

		.select2-selection__rendered {
			width: 100%;
			padding: 0 20px;
			font-size: 16px;
			color: #000;
		}
	}

	.select2 {
		width: 100% !important;

	}

	.select2-container--open {
		.select2-selection--single {
			border-color: @color1 !important;
		}

		& + svg {
			transform: translateY(-50%) rotate(-90deg);
			fill: @color1;
		}
	}

	svg {
		position: absolute;
		fill: #000;
		height: 9px;
		min-width: 9px;
		max-width: 9px;
		right: 18px;
		top: 50%;
		transform: translateY(-50%) rotate(90deg);
		transition: all 0.5s;
	}

	.select2-selection__arrow {
		display: none;
	}
}

.select2-dropdown {
	border-radius: 20px !important;
	transform: translateY(5px);
	border: 1.5px solid #ccc !important;

	.select2-search__field {
		border-radius: 20px;
		padding-top: 8px;
		padding-bottom: 5px;
		border: 1.5px solid #ccc !important;
		padding-left: 15px;
		font-size: 16px;
		color: #000;

		&:focus-visible {
			outline: none;
		}
	}

	.select2-results__option {
		font-size: 16px !important;
		color: #000;
	}

	.select2-results__message {
		padding: 15px 20px;
		font-size: 16px;
		color: #000;
	}

	&.select2-dropdown--above {
		transform: translateY(-5px) !important;
	}
}

.checkbox-wrapper {
	margin-top: 20px;
	cursor: pointer;
	display: block;
	position: relative;

	.radio-box {
		display: flex;
		text-align: left;

		p {
			margin-left: 10px;
			margin-top: 3px;
			line-height: 15px;
			font-size: 16px;
			font-family: 'tt_commonsmedium';
			color: #000;

			a {
				color: @color1;
				text-decoration: underline;
			}
		}

		.box-icon {
			display: block;
			height: 18px;
			min-width: 32px;
			max-width: 32px;
			border-radius: 20px;
			border: 2px solid @color1;
			position: relative;

			.icon-ball {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				position: absolute;
				left: 3px;
				top: 2px;
				background: #B4B4B4;
				transition: all 0.5s;
			}
		}
	}

	input {
		visibility: visible;
		opacity: 0;
		left: 0;
		position: absolute;
		width: auto !important;
	}

	&.checkbox-alert {
		.radio-box {
			p {
				color: #dc3545;			
			}

			.box-icon {
				border-color: #dc3545;
			}
		}
	}

	& + .checkbox-wrapper {
		margin-top: 10px;
	}
}

.checkbox-wrapper > input:checked + .radio-box {
	.box-icon {
		.icon-ball {
			left: 15px;
			background: @color1;
		}
	}
}

.checkbox-wrapper > input:focus-visible + .radio-box {
	outline: 2px solid #000;
}

.header-sidebar {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	transition: all 0.4s;

	.sidebar-service {
		position: absolute;
		height: 100%;
		width: 400px;
		max-width: 100%;
		right: 0;
		top: 0;
		box-shadow: -10px 5px 35px 0px rgba(0, 0, 0, 0.15);
		transform: translateX(100%);
		transition: all 0.4s;

		.service-heading {
			padding: 40px;
			min-height: 116px;
			display: flex;
			justify-content: space-between;
			color: #000;

			.heading {
				font-size: 24px;
				line-height: 28px;
				font-weight: 400;
			}

			.delivery {
				text-align: right;
				font-size: 13px;
				line-height: 18px;
			}
		}

		@z-index-value: 222;

		.service-menu-wrapper {
			position: absolute;
			background: #fff;
			top: 0;
			left: 0;
			transition: all 0.6s;
			z-index: @z-index-value;
			height: 100%;
			width: 100%;

			&.hidden {
				transform: translateX(100%);
				z-index: 1;
			}
		}

		.service-menu-wrapper + .service-menu-wrapper {
			z-index: @z-index-value * 2;
		}

		.service-menu {
			width: 400px;
			max-width: 100%;
			background: #f1f1f1;
			padding: 20px;
			height: 100%;
			max-height: 100%;

			.backlink {
				display: block;
				outline: none;
				padding: 15px 20px;
				border-radius: 50px;
				width: 100%;
				background: #fff;
				border: none;

				.backlink-inner {
					display: flex;
					align-items: center;

					span {
						font-family: 'tt_commonsdemibold';
						color: #000;
						transform: translateY(2px);
					}

					img {
						margin-right: 15px;
						transform: rotate(180deg);
						height: 10px;
					}
				}

				&.backlink-close {
					position: absolute;
					top: 0;
					left: 0;
					background: #fff;

					&.hidden {
						display: none;
					}
				}
			}

			.menu-heading {
				border-top: 45px solid #f1f1f1;
				font-size: 18px;
				text-align: center;
				font-family: 'tt_commonsdemibold';
				padding: 15px 20px;
				color: #000;
			}

			.menu-list {
				list-style: none;
				border-top: 45px solid #f1f1f1;

				li {
					a, button {
						padding: 15px 50px 15px 0;
						width: 100%;
						position: relative;
						border: none;
						outline: none;
						display: flex;
						align-items: center;

						.icon {
							fill: #000;
							height: 20px;
							min-width: 20px;
							max-width: 20px;
							margin-right: 10px;
						}

						span {
							font-family: 'tt_commonsdemibold';
							color: #333;
							transform: translateY(2px);
						}

						&.has-child {
							&:after {
								content: '';
								height: 10px;
								min-width: 10px;
								max-width: 10px;
								position: absolute;
								right: 20px;
								top: 50%;
								transform: translateY(-50%);
								background: url(../img/icons/right-chevron.svg) center;
								background-size: cover;
							}
						}

						&.highlight-border {
							padding-right: 15px;
							padding-left: 15px;
							border-radius: 50px;
							background: #fff;
							border: 2px solid @color1;
							text-align: center;
							justify-content: center;
						}
					}

					&:not(:last-child) {
						border-bottom: 0.5px solid #ccc;
					}
				}

				&.no-border {
					li {
						&:not(:last-child) {
							border-bottom: none;
							margin-bottom: 10px;
						}
					}
				}
			}

			.menu-heading + .menu-list {
				border-top: 0.5px solid #ccc;
			}
		}

		&.active {
			transform: translateX(0);
		}
	}

	&.active {
		z-index: 555;
		visibility: visible;
		opacity: 1;
	}
}

nav.section-header {
	transition: all 0.3s;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 444;
	padding-bottom: 15px;
	background: rgba(255, 255, 255, 0.95);

	.header-top {
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.top-logo {
			img {
				height: 33px;

				&:nth-child(2) {
					display: none;
				}
			}
		}

		.top-menu {
			display: flex;
			align-items: center;

			.divider {
				height: 16px;
				width: 1px;
				background: #ddd;
				margin: 0 25px;
			}

			.menu-language {
				list-style: none;

				li {
					.dropdown {
						button {
							background: none;
							border: none;
							padding: 0;
							color: #000;
							font-size: 14px;
							font-family: 'tt_commonsdemibold';
							outline: none;
							box-shadow: none;

							&:after {
								border: none;
								height: 10px;
								width: 10px;
								background: url('../img/icons/up-arrow.svg') center no-repeat;
								background-size: cover;
								transform: rotate(180deg) translateY(-4px);
							}
						}

						.dropdown-menu {
							margin-top: 10px !important;
							padding: 5px 0;

							li {
								a {
									background: none;
									border: none;
									padding: 5px 10px;
									color: #000;
									font-size: 12px;
									max-width: 70px;
									font-family: 'tt_commonsdemibold';
									padding: 5px 10px;
								}

								& + li {
									margin-left: 0;
								}
							}
						}
					}

					& + li {
						margin-left: 20px;
					}
				}
			}

			.menu-social-media {
				list-style: none;
				display: flex;
				align-items: center;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							fill: #B1B4B6;
							height: 20px;
							width: 20px;
							transition: all 0.5s;

							&:hover {
								fill: @color1;
							}
						}
					}

					&:first-child {
						svg, img {
							transform: translateX(-4px);
						}
					}

					& + li {
						margin-left: 12px;
					}
				}
			}

			.menu-contact {
				display: flex;
				align-items: center;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							height: 20px;
							width: 20px;
							fill: #B1B4B6;
							margin-right: 6px;
						}

						span {
							transition: all 0.5s;
							color: #000;
							font-size: 14px;
							font-family: 'tt_commonsdemibold';
						}

						&:hover {
							span {
								color: @color1;
							}
						}
					}

					.nav-button {
						padding: 0;
						margin: 0;
						border: 2.1px solid @color1;
						background: none;
						border-radius: 50%;
						height: 39.5px;
						width: 39.5px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						outline: none;
						transition: all 0.5s;

						svg, img {
							height: 20px;
							width: 20px;
							fill: #333;
							transition: all 0.5s;
						}

						&:hover, &:focus {
							background: @color1;

							svg, img {
								fill: #fff;
							}
						}
					}

					& + li {
						margin-left: 20px;
					}

					&:last-child {
						display: none;
					}
				}
			}
		}
	}

	.header-flexbox {
		padding-top: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: all 0.3s;

		.flexbox-nav {
			display: flex;
			align-items: center;

			li {
				> a {
					font-size: 16px;
					color: #000;
					font-family: 'tt_commonsdemibold';
				}

				.dropdown {
					a {
						svg {
							margin-left: 10px;
							fill: #000;
							height: 6px;
							min-width: 6px;
							max-width: 6px;
							transform: rotate(90deg);
						}

						span {
							font-size: 16px;
							color: #000;
							font-family: 'tt_commonsdemibold';
						}
					}

					.dropdown-menu {
						li {
							position: relative;

							a {
								font-size: 14px;
								color: #000;
								font-family: 'tt_commonsdemibold';

								span {
									font-size: inherit;
								}

								&:active {
									background: #ccc;
								}

								svg {
									transform: none;
								}
							}

							& + li {
								margin-left: 0;
							}
						}

						> li {
							&:hover {
								background-color: #f1f1f1;

								> .submenu {
									display: block;
								}
							}
						}

						.submenu { 
							display: none;
							position: absolute;
							left: 100%;
							top: -7px;

							&.submenu-left {
								right: 100%;
								left: auto;
							}
						}
					}

					&:hover {
						> .dropdown-menu {
							display: block;
							margin-top: 0;
						}
					}
				}

				& + li {
					margin-left: 25px;
				}
			}
		}

		.flexbox-menu {
			display: flex;
			align-items: center;

			li {
				& + li {
					margin-left: 20px;
				}
			}
		}
	}

	&:hover {
		background: rgba(255, 255, 255, 1);
	}

	&.scrolled {
		.header-flexbox {
			padding-top: 15px;
		}

		&.scrolling-down {
			// opacity: 0;
			// transform: translateY(-30px);
			// pointer-events: none;
		}
	}
}

section.section-welcome {
	margin-top: 20px;
	position: relative;
	overflow: hidden;

	.welcome-item {
		height: 600px;
		background: #eee;
		display: flex;
		align-items: center;
		position: relative;

		.item-background {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;

			.background-shape {
				position: absolute;
				left: 0;
				bottom: 0;
				opacity: 0.1;
			}

			.background-image-desktop {
				object-position: center right;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}

			.background-image-mobile {
				display: none;
				object-position: center;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.item-inner {
			position: relative;
			width: 100%;

			.inner-description {
				max-width: 500px;
				margin-left: 100px;

				h1, h2, h3 {
					font-size: 65px;
					font-family: 'tt_commonsdemibold';
					color: #fff;
				}

				p {
					max-width: 250px;
					margin-top: 10px;
					font-size: 20px;
					font-family: 'tt_commonsmedium';
					color: #fff;
				}

				.btn {
					margin-top: 20px;
				}
			}
		}

		&.dark {
			.item-inner {
				.inner-description {
					h1, h2, h3, p {
						color: #000;
					}
				}
			}
		}
	}

	.swiper-container {
		.swiper-pagination {
			padding: 45px 40px 20px 40px;
			display: flex;
			background: #fff;
			position: absolute;
			bottom: 0;
			right: 0;
			width: auto !important;
			left: auto !important;
			margin: 0;

			.swiper-pagination-bullet {
				display: flex;
				margin: 0;
				padding: 0;
				border-radius: 0;
				width: auto;
				height: auto;
				opacity: 0.5 !important;
				text-align: left;
				font-size: 20px;
				font-family: 'tt_commonsdemibold';
				color: #000;
				opacity: 1;
				line-height: 26px;
				background: none;
				transition: all 0.5s;

				.number {
					margin-right: 7px;
				}

				.name {
					max-width: 130px;
					position: relative;

					&:before {
						content: '';
						height: 3px;
						width: 0;
						position: absolute;
						top: -15px;
						left: 0;
						background: @color1;
						transition: all 0.5s;
					}
				}

				& + .swiper-pagination-bullet {
					margin-left: 35px;
				}
			}

			.swiper-pagination-bullet-active {
				opacity: 1 !important;

				.name {
					&:before {
						width: 100%;
					}
				}
			}
		}
	}
}

section.section-advantages {
	padding-top: 80px;

	.advantages-inner {
		display: flex;
		justify-content: space-around;
		padding: 0 5%;

		.inner-service {
			text-align: center;

			.service-icon {
				margin: 0 auto;

				svg, img {
					width: 40px;
					height: 40px;
					fill: @color1;
				}
			}

			span {
				display: block;
				line-height: 20px;
				margin-top: 20px;
				font-size: 20px;
				color: #000;
				font-family: 'tt_commonsmedium';
			}
		}
	}
}

.section-heading {
	h1, h2, h3 {
		font-size: 35px;
		font-family: 'tt_commonsdemibold';
		color: #000;
		letter-spacing: 2px;
	}
}

section.section-offer {
	padding-top: 80px;

	.offer-inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.inner-service {
			margin-top: 30px;
			width: 31.5%;
			text-align: center;

			.service-image {
				width: 100%;
				padding-top: 60%;
				position: relative;
				overflow: hidden;
				border-radius: 3px;

				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-position: center;
					object-fit: cover;
					transition: all 0.5s;
				}

				.image-cover {
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					background: @color1;
					opacity: 0.2;
					transition: all 0.5s;
				}
			}

			.service-name {
				margin-top: 20px;
				font-size: 20px;
				color: #000;
				font-family: 'tt_commonsmedium';
			}

			.service-more {
				margin-top: 5px;
				font-family: "tt_commonsdemibold";
				font-size: 14px;
				color: @color1;
			}

			&:hover {
				.service-image {
					img {
						transform: scale(1.1);
					}

					.image-cover {
						width: 100%;
					}
				}
			}
		}
	}
}

section.section-cooperate {
	margin-top: 100px;

	.section-heading {
		margin-bottom: 30px;
	}

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.cooperate-inner {
		img {
			height: 45px;

			&.h20 {
				height: 20px;
			}

			&.h25 {
				height: 25px;
			}

			&.h30 {
				height: 30px;
			}

			&.h35 {
				height: 35px;
			}

			&.h40 {
				height: 40px;
			}

			&.h50 {
				height: 50px;
			}
		}
	}
}

section.section-about {
	margin-top: 100px;

	.about-inner-wrapper {
		position: relative;

		.inner-background {
			position: absolute;
			top: 50%;
			left: 10%;
			width: 80%;
			opacity: 0.1;
			transform: translateY(-50%);
		}
	}

	.about-inner {
		max-width: 800px;
		margin: 0 auto;
		text-align: center;

		p, li {
			font-size: 14px;
		}
	}
}

section.static-page-section-content {
	> * {
		margin-top: 100px;
	}

	.content-welcome {
		margin-top: 20px;
		min-height: 500px;
		background: #eee;
		display: flex;
		align-items: center;
		position: relative;

		.welcome-background {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;

			img {
				object-position: center;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.welcome-inner {
			position: relative;
			padding: 100px 0;
			width: 100%;

			.inner-description {
				max-width: 500px;

				h1, h2, h3 {
					font-size: 65px;
					font-family: 'tt_commonsdemibold';
					color: #fff;
				}

				p {
					max-width: 350px;
					margin-top: 10px;
					font-size: 20px;
					font-family: 'tt_commonsmedium';
					color: #fff;
				}

				.btn {
					margin-top: 20px;
				}
			}
		}

		&.dark {
			.welcome-inner {
				.inner-description {
					h1, h2, h3, p {
						color: #000;
					}
				}
			}
		}
	}

	.content-2-text {
		.text-inner {
			display: flex;
			justify-content: space-between;

			> * {
				width: 48%;
			}

			&.w-100 {
				max-width: 100%;
			}
		}
	}

	.content-3-text {
		.text-inner {
			display: flex;
			justify-content: space-between;

			> * {
				width: 30%;
			}
		}
	}

	.content-gallery {
		.static-text-wrapper {
			margin-bottom: 35px;
		}

		.gallery-inner {
			margin-top: calc(~'-4% / 3');
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.inner-service {
				margin-top: calc(~'4% / 3');
				display: block;
				width: 24%;
				padding-top: 16%;
				border-radius: 3px;
				position: relative;
				overflow: hidden;
				background: #f9f9f9;

				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}

				&.service-hidden {
					margin-top: 0 !important;
					padding-top: 0 !important;
					opacity: 0 !important;
					visibility: hidden;
				}
			}

			&.gallery-3 {
				margin-top: calc(~'-2.65% / 2');

				.inner-service {
					margin-top: calc(~'2.65% / 2');
					width: 32.45%;
					padding-top: 21.65%;
				}
			}

			&.gallery-collapsed {
				margin-top: 0;
				padding-top: 0;
			}
		}

		.btn-gallery-more {
			margin-top: 30px;

			&.hidden {
				display: none;
			}
		}
	}

	.content-photo {
		img {
			width: 100%;
			border-radius: 3px;
		}
	}

	.content-2-photo {
		margin-top: 40px;

		.photo-inner {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.photo-service {
				width: 48%;

				img {
					width: 100%;
					border-radius: 3px;
				}
			}
		}
	}

	.content-3-photo {
		margin-top: 40px;

		.photo-inner {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.photo-service {
				width: 31%;

				img {
					width: 100%;
					border-radius: 3px;
				}
			}
		}
	}

	.photo-service {
		text-align: center;

		img {
			+ .service-title {
				margin-top: 15px;
			}
		}

		.service-name {
			margin-top: 15px;
			font-size: 20px;
			color: #000;
			transition: all 0.5s;
		}

		.service-title {
			color: #888;
			font-size: 14px;
			font-family: 'tt_commonsmedium';
		}
	}

	a.photo-service {
		&:hover {
			.service-name {
				color: @color1;
			}
		}
	}

	.content-photo-text {
		.photo-text-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			img {
				width: 48%;
				border-radius: 3px;
			}

			.static-text-wrapper {
				width: 48%;
			}
		}
	}

	.content-buttons {
		.buttons-inner {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			> * {
				margin: 7px;

				&:nth-child(2) {
					background: darken(@color1, 5%);
					border-color: darken(@color1, 5%);

					&:hover {
						background: darken(@color1, 15%);
						border-color: darken(@color1, 15%);
					}
				}

				&:nth-child(3) {
					background: darken(@color1, 10%);
					border-color: darken(@color1, 10%);

					&:hover {
						background: darken(@color1, 20%);
						border-color: darken(@color1, 20%);
					}
				}

				&:nth-child(4) {
					background: darken(@color1, 15%);
					border-color: darken(@color1, 15%);

					&:hover {
						background: darken(@color1, 25%);
						border-color: darken(@color1, 25%);
					}
				}

				&:nth-child(5) {
					background: darken(@color1, 20%);
					border-color: darken(@color1, 20%);

					&:hover {
						background: darken(@color1, 30%);
						border-color: darken(@color1, 30%);
					}
				}

				&:nth-child(6) {
					background: darken(@color1, 25%);
					border-color: darken(@color1, 25%);

					&:hover {
						background: darken(@color1, 35%);
						border-color: darken(@color1, 35%);
					}
				}

				&:nth-child(7) {
					background: darken(@color1, 30%);
					border-color: darken(@color1, 30%);

					&:hover {
						background: darken(@color1, 40%);
						border-color: darken(@color1, 40%);
					}
				}
			}
		}
	}

	.content-accordion {
		.accordion {
			max-width: 1000px;

			.accordion-item {
				.accordion-header {
					.accordion-button {
						color: @color1;
						font-family: 'tt_commonsmedium';
						font-size: 28px;
						box-shadow: none;
						text-align: left;
						border: none;
						padding: 0 0 10px 0;
						background: none;

						span {
							padding-right: 50px;
							display: block;
							position: relative;
							width: 100%;

							&:after {
								content: '';
								position: absolute;
								background: url('../img/icons/up-arrow-blue.svg') center;
								background-size: cover;
								width: 20px;
								height: 20px;
								transform: translateY(-4px);
								top: 9px;
								right: 7px;
								transition: all 0.5s;
								filter: grayscale(0);
							}
						}

						&.collapsed {
							color: #000;

							span {
								&:after {
									transform: translateY(-4px) rotate(180deg);
									filter: grayscale(100%);
								}
							}
						}

						&:after {
							content: none;
						}
					}
				}

				.accordion-collapse {
					border: none;
				}

				.accordion-body {
					padding: 20px 0 10px 0;
				}

				& + .accordion-item {
					margin-top: 20px;
				}
			}
		}
	}

	.content-tiles {
		margin-top: 40px;

		.tiles-inner {
			margin-top: -30px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.inner-service {
				margin-top: 30px;
				width: 31.5%;
				text-align: center;

				.service-image {
					width: 100%;
					padding-top: 60%;
					position: relative;
					overflow: hidden;
					border-radius: 3px;

					img {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						object-position: center;
						object-fit: cover;
						transition: all 0.5s;
					}

					.image-cover {
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 100%;
						background: @color1;
						opacity: 0.2;
						transition: all 0.5s;
					}
				}

				.service-name {
					margin-top: 20px;
					font-size: 20px;
					color: #000;
					font-family: 'tt_commonsmedium';
				}

				.service-more {
					margin-top: 5px;
					font-family: "tt_commonsdemibold";
					font-size: 14px;
					color: @color1;
				}

				&:hover {
					.service-image {
						img {
							transform: scale(1.1);
						}

						.image-cover {
							width: 100%;
						}
					}
				}

				&.service-hidden {
					margin: 0;
					padding: 0;
					visibility: hidden;
					opacity: 0;
				}
			}
		}
	}

	.content-download {
		.download-wrapper {
			display: flex;
			justify-content: space-between;
		}

		.download-inner {
			display: flex;
			flex-direction: column;
			margin-top: 25px;
			width: 31.5%;

			.inner-service {
				margin-top: 15px;
				display: flex;
				align-items: center;
				border-radius: 8px;
				padding: 0 15px 0 0;

				.icon {
					min-width: 35px;
					max-width: 35px;
					height: 35px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: @color1;
					margin-right: 15px;

					svg {
						height: 22px;
						width: 22px;
						fill: #fff;
					}
				}

				span {
					font-size: 16px;
					font-family: 'tt_commonsdemibold';
					color: #000;
					transition: all 0.4s;
				}

				&:hover {
					span {
						color: @color1;
					}
				}
			}
		}
	}

	.content-icons {
		.icons-inner {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.inner-service {
				background: none;
				border: none;
				outline: none;
				margin-top: 30px;
				width: 25%;
				padding: 0 15px;
				text-align: center;

				.service-avatar {
					img, svg {
						height: 40px;
						width: 40px;
						fill: @color1;
					}
				}

				.service-name {
					margin-top: 15px;
					font-size: 20px;
					color: #000;
					transition: all 0.5s;
				}

				&.service-hidden {
					margin: 0;
					padding: 0;
					visibility: hidden;
				}
			}

			button {
				&:hover {
					.service-name {
						color: @color1;
					}
				}
			}
		}
	}

	.content-text, .content-2-text, .content-3-text {
		& + .content-buttons {
			margin-top: 50px;
		}
	}
}

section.aktualnosci-section-content {
	padding-top: 100px;

	.news-flexbox {
		max-width: 900px;
	}

	.btn-more-products-wrapper {
		max-width: 900px;
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			width: 65%;

			.body-search-results {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				margin-bottom: 50px;

				.results-service {
					.heading {
						font-size: 16px;
						font-family: 'tt_commonsmedium';
						color: #000;	
					}

					.value {
						margin-top: 8px;
						font-size: 30px;
						line-height: 30px;
						font-style: italic;
						font-family: 'tt_commonsmedium';
						color: #000;
					}
				}

				.btn {
					white-space: nowrap;
				}
			}

			.news-flexbox {
				max-width: 100%;
			}

			.btn-more-products-wrapper {
				max-width: 100%;
			}
		}

		.flexbox-sidebar {
			width: 26%;

			.sidebar-search-engine {
				.input-service {
					margin-top: 0;
					position: relative;

					input {
						text-align: left;
						padding-left: 14px;
						padding-right: 40px;
						border-color: @color1;
					}

					.btn-search {
						position: absolute;
						bottom: 0;
						border: none;
						outline: none;
						right: 0;
						height: 45px;
						width: 45px;
						border-radius: 20px;
						background: @color1;
						display: flex;
						align-items: center;
						justify-content: center;

						svg, img {
							height: 18px;
							width: 18px;
							fill: #fff;
						}
					}
				}
			}

			.sidebar-categories {
				margin-top: 50px;

				.heading {
					text-align: center;
					font-size: 16px;
					font-family: 'tt_commonsmedium';
					color: #000;
				}

				.categories-flexbox {
					margin-top: 10px;
					display: flex;
					flex-wrap: wrap;

					.flexbox-service {
						margin-top: 5px;
						margin-right: 5px;
						display: block;
						color: #000;
						font-size: 18px;
						font-family: 'tt_commonsmedium';
						padding: 5px 13px 2px 13px;
						border-radius: 13px;
						border: 1.5px solid @color1;
						transition: all 0.5s;

						&:hover {
							border-color: darken(@color1, 10%);
						}

						&.active {
							background: @color1;
							color: #fff;

							&:hover {
								border-color: @color1;
							}
						}
					}
				}

				.input-service {
					display: none;
				}

				.dropdown {
					button {
						background: none;
						border: none;
						outline: none;
						border: 1.5px solid #ccc;
						height: 45px;
						width: 100%;
						border-radius: 20px;
					}
				}
			}
		}
	}
}

section.wpis-section-content {
	margin-top: 20px;

	.content-welcome {
		background: linear-gradient(90deg, #A6ADB3 0%, rgba(216, 220, 223, 0.73) 100%);
		padding: 60px 0;

		.welcome-inner {
			display: flex;
			align-items: center;

			svg {
				margin-right: 15px;
				fill: #fff;
				transform: rotate(-90deg);
				height: 30px;
				min-width: 30px;
				max-width: 30px;
			}

			.inner-text {
				transform: translateY(3px);
				font-size: 28px;
				color: #fff;
				font-family: 'tt_commonsdemibold';

				&:after {
					content: '';
					width: 0;
					height: 2px;
					background: #fff;
					position: absolute;
					bottom: 2px;
					left: 0;
					transition: all 0.5s;
				}
			}

			&:hover {
				.inner-text {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.content-main-image {
		margin-top: 50px;

		img {
			width: 100%;
			border-radius: 3px;
		}
	}
}

section.wniosek-section-content {
	padding-top: 100px;
	position: relative;

	.content-letters {
		position: absolute;
		width: 70%;
		top: 500px;
		right: 15%;
		opacity: 0.1;
	}

	.content-inner {
		position: relative;
		max-width: 630px;
		margin: 0 auto;

		.inner-heading {
			text-align: center;

			h1 {
				font-size: 65px;
				font-family: 'tt_commonsdemibold';
				color: #000;
			}

			p {
				margin-top: 10px;
				font-size: 30px;
				font-family: 'tt_commonsmedium';
				color: #444;
			}
		}

		.inner-description {
			margin-top: 30px;
			border-radius: 20px;
			padding: 30px;
			background: linear-gradient(-75deg, #6A747C 0%, rgba(151, 161, 169, 0.73) 100%);
			text-align: center;

			p {
				font-size: 16px;
				font-family: 'tt_commonsmedium';
				color: #fff;
			}
		}

		.inner-form {
			margin-top: 50px;

			.form-checkbox-wrapper {
				margin-top: 15px;
				margin-bottom: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				.checkbox-wrapper {
					margin: 5px 15px !important;
				}
			}

			.form-subheading {
				margin-top: 35px;
				text-align: center;

				h2 {
					font-size: 35px;
					font-family: 'tt_commonsdemibold';
					color: #000;
				}

				p {
					font-size: 17px;
					line-height: 21px;
					font-family: 'tt_commonsmedium';
					color: #444;
					max-width: 370px;
					margin: 10px auto 0 auto;
				}
			}

			.form-message {
				margin-top: 20px;
				border-radius: 15px;
				background: #f1f1f1;
				border-left: 3px solid #FF0000;
				padding: 15px;

				p {
					font-size: 15px;
					line-height: 18px;
					font-family: 'tt_commonsmedium';
					color: #000;
				}
			}

			.btn {
				margin-top: 50px;
			}
		}
	}
}

section.wykladowcy-section-content {
	.content-inner {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.inner-service {
			background: none;
			border: none;
			outline: none;
			margin-top: 30px;
			width: 25%;
			padding: 0 15px;
			text-align: center;

			.service-avatar {
				img, svg {
					height: 40px;
					width: 40px;
					fill: @color1;
				}
			}

			.service-name {
				margin-top: 15px;
				font-size: 20px;
				color: #000;
				transition: all 0.5s;
			}

			.service-title {
				color: #888;
				font-size: 14px;
				font-family: 'tt_commonsmedium';
			}

			&.service-hidden {
				margin: 0;
				padding: 0;
				visibility: hidden;
			}
		}

		button {
			&:hover {
				.service-name {
					color: @color1;
				}
			}
		}
	}
}

section.kontakt-section-content {
	padding-top: 100px;

	.content-inner {
		.inner-heading {
			text-align: center;

			h1 {
				font-size: 65px;
				font-family: 'tt_commonsdemibold';
				color: #000;
			}

			p {
				margin-top: 10px;
				font-size: 30px;
				font-family: 'tt_commonsmedium';
				color: #444;
			}
		}

		.inner-map {
			margin-top: 70px;

			.map-service {
				border-radius: 5px;
				height: 400px;
				width: 100%;

				.gm-ui-hover-effect {
					outline: none;
				}
			}
		}

		.inner-flexbox {
			margin-top: 70px;
			display: flex;
			justify-content: space-between;
			padding: 0 10%;

			.flexbox-service {
				text-align: center;

				.service-name {
					font-size: 35px;
					font-family: 'tt_commonsmedium';
					line-height: 39px;
					color: #000;
					transition: all 0.5s;
				}

				.service-description {
					margin-top: 20px;
					font-family: 'tt_commonslight';
					font-size: 16px;
					line-height: 21px;
					color: #000;

					b {
						font-family: 'tt_commonsdemibold';
					}
				}

				.service-contact {
					margin-top: 20px;

					li {
						a {
							display: flex;
							align-items: center;
							justify-content: center;

							svg, img {
								height: 20px;
								width: 20px;
								fill: @color1;
								margin-right: 6px;
							}

							span {
								transition: all 0.5s;
								color: #000;
								font-size: 16px;
								font-family: 'tt_commonsdemibold';
							}

							&:hover {
								span {
									color: @color1;
								}
							}
						}

						& + li {
							margin-top: 8px;
						}
					}
				}
			}
		}

		.inner-info {
			margin-top: 70px;
			display: flex;
			justify-content: center;

			.info-service {
				text-align: center;

				.service-icon {
					img, svg {
						height: 60px;
						width: 60px;
					}
				}

				.service-name {
					margin-top: 20px;
					font-size: 35px;
					font-family: 'tt_commonsmedium';
					line-height: 39px;
					color: #000;
				}

				.service-description {
					margin-top: 10px;
					font-size: 20px;
					line-height: 24px;
					color: #888;
				}

				& + .info-service {
					margin-left: 100px;
				}
			}
		}
	}
}

section.error-section-content {
	background: #f1f1f1;
	position: relative;
	overflow: hidden;

	.content-inner {
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.inner-description {
			width: 45%;

			.description-error-number {
				font-size: 80px;
				font-weight: 700;
				color: @color1;
				line-height: 100px;
			}

			.buttons-wrapper {
				margin-top: 30px;
			}
		}

		.inner-image {
			width: 45%;
			text-align: center;

			img, svg {
				width: 100%;
				max-width: 400px;
				fill: @color1;
			}
		}
	}
}

section.section-footer {
	margin-top: 100px;
	padding: 80px 0 0 0;
	background: #f1f1f1;

	.footer-inner {
		display: flex;
		justify-content: space-between;

		.inner-service {
			.service-logo {
				height: 72px;
			}

			address {
				margin: 30px 0 0 0;
				font-size: 16px;
				color: #000;
				font-family: 'tt_commonslight';
			}

			.service-contact {
				margin-top: 30px;

				li {
					a {
						display: flex;
						align-items: center;

						svg, img {
							height: 20px;
							width: 20px;
							fill: #B1B4B6;
							margin-right: 6px;
						}

						span {
							transition: all 0.5s;
							color: #000;
							font-size: 16px;
							font-family: 'tt_commonsdemibold';
						}

						&:hover {
							span {
								color: @color1;
							}
						}
					}

					& + li {
						margin-top: 8px;
					}
				}
			}

			.service-buttons {
				margin-top: 50px;
				list-style: none;
				display: inline-block;

				li {
					a {
						width: 100%;
					}

					& + li {
						margin-top: 10px;
					}
				}
			}

			.service-list {
				li {
					&:not(:last-child) {
						margin-bottom: 7px;
					}

					&.collapse {
						display: block;
					}

					&.heading {
						font-size: 16px;
						font-family: 'tt_commonsdemibold';
						color: #000;
						margin-bottom: 18px;

						a, button {
							padding: 0;
							background: none;
							border: none;
							outline: none;
							width: 100%;
							text-align: left;
							font-size: 16px;
							font-family: 'tt_commonsdemibold';
							pointer-events: none;
							display: block;

							img {
								display: none;
								position: absolute;
								top: 5px;
								right: 15px;
								height: 12px;
								min-width: 12px;
								max-width: 12px;
								transform: rotate(90deg);
							}

							&:hover {
								color: #000;
							}
						}
					}

					a {
						transition: all 0.5s;
						font-size: 16px;
						color: #000;
						font-family: 'tt_commonslight';

						&:hover {
							color: @color1;
						}
					}
				}

				& + .service-list {
					margin-top: 50px;
				}
			}
		}
	}

	.footer-info {
		padding: 40px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.info-service {
			display: flex;
			align-items: center;

			p {
				white-space: nowrap;
				font-size: 14px;
				color: #000;
				font-family: 'tt_commonsregular';
			}

			.qed {
				margin-left: 32px;
				width: 75px;
			}

			.bip {
				width: 150px;
			}
		}
	}
}

.footer-credits {
	padding: 15px;
	background: @color1;
	text-align: center;

	p {
		font-size: 12px;
		color: #fff;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}