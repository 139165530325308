
// Extralarge devices (desktops, less than 1400px)
@media (max-width: 1399.98px) {

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	body {
		.btn {
			font-size: 15px;
			padding: 14px 30px 12px 30px;

			&.btn-small {
				padding: 9px 18px 7px 18px;
				font-size: 13px;
			}
		}
	}

	nav.section-header {
		.header-top {
			.top-logo {
				img {
					height: 30px;
				}
			}

			.top-menu {
				.divider {
					margin: 0 15px;
				}

				.menu-language {
					li {
						.dropdown {
							button {
								font-size: 13px;

								&:after {
									transform: rotate(180deg) translateY(-5px);
								}
							}
						}
					}
				}

				.menu-social-media {
					li {
						a {
							svg, img {
								height: 17px;
								width: 17px;
							}
						}

						& + li {
							margin-left: 8px;
						}
					}
				}

				.menu-contact {
					li {
						a {
							span {
								font-size: 13px;
							}
						}

						& + li {
							margin-left: 18px;
						}
					}
				}
			}
		}

		.header-flexbox {
			padding-top: 30px;

			.flexbox-nav {
				li {
					> a {
						font-size: 14px;
					}

					.dropdown {
						a {
							span {
								font-size: 14px;
							}
						}
					}

					& + li {
						margin-left: 20px;
					}
				}
			}

			.flexbox-menu {
				li {
					& + li {
						margin-left: 12px;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-item {
			height: 500px;

			.item-inner {
				.inner-description {
					margin-left: 0;

					h1, h2, h3 {
						font-size: 55px;
					}
				}
			}
		}
	}

	section.section-cooperate {
		.cooperate-inner {
			img {
				height: 45px * 0.8;

				&.h20 {
					height: 20px * 0.8;
				}

				&.h25 {
					height: 25px * 0.8;
				}

				&.h30 {
					height: 30px * 0.8;
				}

				&.h35 {
					height: 35px * 0.8;
				}

				&.h40 {
					height: 40px * 0.8;
				}

				&.h50 {
					height: 50px * 0.8;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-welcome {
			min-height: 440px;

			.welcome-inner {
				padding: 80px 0;

				.inner-description {
					h1, h2, h3 {
						font-size: 55px;
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-inner {
			.inner-flexbox {
				padding: 0;
			}
		}
	}

	section.section-footer {
		.footer-inner {
			flex-wrap: wrap;

			.inner-service {
				&:first-child {
					margin-bottom: 50px;
					width: 100%;
					display: flex;

					> * {
						& + * {
							margin-left: 50px;
						}
					}
				}

				.service-logo {
					height: 72px;
				}

				address {
					margin: 0;
				}

				.service-contact {
					margin-top: 0;
				}

				.service-buttons {
					margin-top: 0;
					display: flex;

					li {
						& + li {
							margin-top: 0;
							margin-left: 15px;
						}
					}
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.header-sidebar {
		display: block;
	}

	nav.section-header {
		.header-top {
			.top-menu {
				.menu-contact {
					li {
						&:nth-child(2), &:nth-child(3) {
							display: none;
						}

						&:last-child {
							display: block;
						}

						& + li {
							margin-left: 10px;
						}
					}
				}
			}
		}

		.header-flexbox {
			display: none;
		}
	}

	section.section-welcome {
		.swiper-container {
			.swiper-pagination {
				padding: 35px 30px 10px 30px;

				.swiper-pagination-bullet {
					font-size: 16px;
					line-height: 20px;

					.number {
						margin-right: 5px;
					}

					.name {
						max-width: 100px;

						&:before {
							top: -12px;
						}
					}

					& + .swiper-pagination-bullet {
						margin-left: 28px;
					}
				}
			}
		}
	}

	section.section-cooperate {
		.cooperate-inner {
			img {
				height: 45px * 0.67;

				&.h20 {
					height: 20px * 0.67;
				}

				&.h25 {
					height: 25px * 0.67;
				}

				&.h30 {
					height: 30px * 0.67;
				}

				&.h35 {
					height: 35px * 0.67;
				}

				&.h40 {
					height: 40px * 0.67;
				}

				&.h50 {
					height: 50px * 0.67;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-3-text {
			.text-inner {
				display: block;

				> * {
					width: 100%;

					& + * {
						margin-top: 70px;
					}
				}
			}
		}

		.content-icons {
			.icons-inner {
				.inner-service {
					width: 100% / 3;
				}
			}
		}
	}

	section.aktualnosci-section-content {
		.content-flexbox {
			flex-direction: column-reverse;

			.flexbox-body {
				margin-top: 50px;
				width: 100%;
			}

			.flexbox-sidebar {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				.sidebar-search-engine {
					.input-service {
						input {
							min-width: 250px;
						}
					}
				}

				.sidebar-categories {
					margin-top: 0;

					.heading {
						display: none;
					}

					.categories-flexbox {
						display: none;
					}

					.input-service {
						margin-top: 0;
						display: block;
						min-width: 250px;
					}
				}
			}
		}
	}

	section.wniosek-section-content {
		.content-letters {
			width: 98%;
			right: 1%;
		}
	}

	section.wykladowcy-section-content {
		.content-inner {
			.inner-service {
				width: 100% / 3;
			}
		}
	}

	section.kontakt-section-content {
		.content-inner {
			.inner-flexbox {
				.flexbox-service {
					width: 30%;
				}
			}
		}
	}

	section.section-footer {
		.footer-inner {
			justify-content: flex-start;

			.inner-service {
				width: 100% / 3;

				&:first-child {
					margin-bottom: 0;

					> * {
						& + * {
							margin-left: 30px;
						}
					}
				}

				&:not(:first-child) {
					margin-top: 40px;
				}

				.service-buttons {
					display: block;

					li {
						& + li {
							margin-top: 10px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.news-flexbox {
		margin-top: -50px;

		.news-service {
			width: 100%;
			margin-top: 50px;

			.name {
				font-size: 30px;
				line-height: 33px;
			}

			.btn-more {
				margin-top: 25px;
			}
		}
	}

	.static-text-wrapper {
		h1 {
			font-size: 45px;
		}

		h2 {
			font-size: 38px;
		}

		h3 {
			font-size: 32px;
		}

		h4 {
			font-size: 26px;
		}

		h5 {
			font-size: 20px;
		}

		h6 {
			font-size: 17px;
		}
	}

	.btn-more-products-wrapper {
		margin-top: 60px;

		.more-products-counter {
			margin-bottom: 22px;
		}
	}

	nav.section-header {
		.header-top {
			padding-top: 20px;

			.top-logo {
				img {
					height: 75px;

					&:nth-child(1) {
						display: none;
					}

					&:nth-child(2) {
						display: block;
					}
				}
			}

			.top-menu {
				flex-wrap: wrap;
				justify-content: flex-end;

				.divider {
					margin: 0 12px;
					background: none;

					&.breakpoint {
						background: none;
						height: 10px;
						width: 100%;
					}
				}

				.menu-language {
					li {
						.dropdown {
							.dropdown-menu {
								min-width: auto;
								padding-right: 2px;
							}
						}
					}
				}

				.menu-social-media {
					li {
						a {
							height: 25px;
							width: 25px;
							background: #888;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							transition: all 0.5s;

							svg, img {
								fill: #fff;
								height: 15px;
								width: 15px;
							}

							&:first-child {
								svg, img {
									transform: none;
								}
							}

							&:hover {
								background: darken(@color1, 10%);

								svg {
									fill: #fff;
								}
							}
						}
					}
				}

				.menu-contact {
					li {
						&:nth-child(2), &:nth-child(3) {
							display: none;
						}

						&:last-child {
							display: block;
						}

						& + li {
							margin-left: 10px;
						}
					}
				}
			}
		}

		.header-flexbox {
			display: none;
		}
	}

	section.section-welcome {
		&:after {
			content: '';
			width: 100%;
			height: 2px;
			bottom: -1px;
			left: 0;
			background: #fff;
			position: absolute;
		}

		.welcome-item {
			height: calc(~'100vh - 195px');
			flex-direction: column;

			.container {
				max-width: 100%;
				padding: 0;
			}

			.item-background {
				position: relative;
				height: 100%;

				.background-shape {
					width: 100% !important;
					z-index: 1;
				}

				.background-image-desktop {
					display: none;
				}

				.background-image-mobile {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.item-inner {
				width: 100%;
				position: relative;

				.inner-description {
					background: #fff;
					padding: 30px 30px 5px 30px;
					max-width: 100%;
					text-align: center;

					h1, h2, h3 {
						font-size: 45px;
						line-height: 44px;
						color: #000;
					}

					p {
						margin: 10px auto 0 auto;
						font-size: 16px;
						max-width: 500px;
						color: #000;
					}

					.btn {
						margin-top: 15px;
					}
				}
			}
		}

		.swiper-container {
			.swiper-pagination {
				display: none;
			}
		}
	}

	section.section-advantages {
		padding-top: 70px;

		.advantages-inner {
			padding: 0;

			.inner-service {
				text-align: center;

				.service-icon {
					margin: 0 auto;

					svg, img {
						width: 37px;
						height: 37px;
					}
				}

				span {
					line-height: 17px;
					font-size: 17px;
				}
			}
		}
	}

	section.section-offer {
		padding-top: 70px;

		.offer-inner {
			.inner-service {
				width: 48.5%;

				.service-name {
					margin-top: 12px;
					font-size: 18px;
				}

				.service-more {
					margin-top: 0;
					font-size: 13px;
				}
			}
		}
	}

	section.section-cooperate {
		.cooperate-inner {
			img {
				height: 45px * 0.75;

				&.h20 {
					height: 20px * 0.75;
				}

				&.h25 {
					height: 25px * 0.75;
				}

				&.h30 {
					height: 30px * 0.75;
				}

				&.h35 {
					height: 35px * 0.75;
				}

				&.h40 {
					height: 40px * 0.75;
				}

				&.h50 {
					height: 50px * 0.75;
				}
			}
		}
	}

	section.section-about {
		margin-top: 70px;
	}

	section.static-page-section-content {
		> * {
			margin-top: 70px;
		}

		.content-welcome {
			min-height: 250px;

			.welcome-background {
				img {
					object-position: center;
				}

				&:after {
					content: '';
					display: block;
					background: rgba(0, 0, 0, 0.4);
					height: 100%;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.welcome-inner {
				padding: 70px 0;

				.inner-description {
					h1, h2, h3 {
						font-size: 45px;
						line-height: 44px;
						font-family: 'tt_commonsmedium';
					}

					p {
						margin: 10px 0 0 0;
						font-size: 16px;
						max-width: 500px;
					}

					.btn {
						margin-top: 15px;
					}
				}
			}

			&.dark {
				.welcome-background {
					&:after {
						background: rgba(255, 255, 255, 0.4);
					}
				}
			}
		}

		.content-2-text {
			.text-inner {
				display: block;

				> * {
					width: 100%;

					& + * {
						margin-top: 70px;
					}
				}
			}
		}

		.content-gallery {
			.static-text-wrapper {
				margin-bottom: 35px;
			}

			.gallery-inner {
				margin-top: -3%;

				.inner-service {
					width: 48.5%;
					padding-top: 32%;
					margin-top: 3%;
				}

				&.gallery-3 {
					margin-top: -3%;

					.inner-service {
						width: 48.5%;
						padding-top: 32%;
						margin-top: 3%;
					}
				}
			}
		}

		.content-photo-text {
			.photo-text-inner {
				flex-direction: column;

				img {
					width: 100%;
					border-radius: 3px;

					& + .static-text-wrapper {
						margin-top: 70px;
					}
				}

				.static-text-wrapper {
					width: 100%;

					& + img {
						margin-top: 70px;
					}
				}
			}
		}

		.content-tiles {
			.tiles-inner {
				.inner-service {
					width: 48.5%;
				}
			}
		}

		.content-download {
			.download-wrapper {
				flex-direction: column;
			}

			.download-inner {
				width: 100%;

				& + .download-inner {
					margin-top: 0;
				}
			}
		}

		.content-icons {
			.icons-inner {
				.inner-service {
					width: 50%;
					padding: 0 10px;
				}
			}
		}
	}

	section.aktualnosci-section-content {
		padding-top: 70px;
	}

	section.wpis-section-content {
		.content-welcome {
			padding: 40px 0;

			.welcome-inner {
				svg {
					margin-right: 10px;
					transform: rotate(-90deg);
					height: 22px;
					min-width: 22px;
					max-width: 22px;
				}

				.inner-text {
					transform: translateY(2px);
					font-size: 22px;
				}
			}
		}
	}

	section.wniosek-section-content {
		padding-top: 70px;

		.content-inner {
			max-width: 630px;

			.inner-heading {
				text-align: center;

				h1 {
					font-size: 45px;
					line-height: 44px;
				}

				p {
					font-size: 22px;
				}
			}

			.inner-description {
				margin-top: 25px;
				padding: 25px;

				p {
					font-size: 15px;
				}
			}

			.inner-form {
				margin-top: 35px;

				.form-subheading {
					margin-top: 25px;

					h2 {
						font-size: 30px;
					}

					p {
						font-size: 16px;
						line-height: 19px;
					}
				}
			}
		}
	}

	section.wykladowcy-section-content {
		.content-inner {
			.inner-service {
				width: 50%;
				padding: 0 10px;
			}
		}
	}

	section.kontakt-section-content {
		padding-top: 70px;

		.content-inner {
			.inner-heading {
				h1 {
					font-size: 45px;
					line-height: 44px;
				}

				p {
					font-size: 22px;
				}
			}

			.inner-map {
				margin-top: 30px;
			}

			.inner-flexbox {
				margin-top: 20px;
				flex-direction: column;
				align-items: center;

				.flexbox-service {
					margin-top: 50px;
					width: 100%;
				}
			}
		}
	}

	section.error-section-content {
		.content-inner {
			flex-direction: column-reverse;

			.inner-description {
				margin-top: 40px;
				width: 100%;
				text-align: center;

				.description-error-number {
					font-size: 80px * 0.5;
					line-height: 100px * 0.5;
				}
			}

			.inner-image {
				svg, img {
					height: 240px;
					width: 240px;
				}
			}
		}
	}

	section.section-footer {
		margin-top: 70px;
		padding: 70px 0 30px 0;

		.footer-inner {
			justify-content: center;

			.inner-service {
				text-align: center;

				&:first-child {
					display: block;

					> * {
						& + * {
							margin-top: 20px;
							margin-left: 0;
						}
					}
				}

				&:not(:first-child) {
					margin-top: 40px;
				}

				.service-contact {
					li {
						a {
							justify-content: center;
						}
					}
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.btn-fixed {
		top: 65%;
	}

	section.section-advantages {
		.advantages-inner {
			flex-wrap: wrap;
			padding: 0 10%;

			.inner-service {
				width: 50%;

				&:nth-child(3), &:nth-child(4) {
					margin-top: 40px;
				}
			}
		}
	}

	section.section-cooperate {
		margin-top: 70px;
	}

	section.wniosek-section-content {
		.content-background {
			display: none;
		}
	}

	section.aktualnosci-section-content {
		.content-flexbox {
			.flexbox-body {
				margin-top: 50px;
				width: 100%;

				.body-search-results {
					flex-direction: column;
					align-items: center;

					.results-service {
						width: 100%;
						text-align: center;
					}

					.btn {
						margin-top: 20px;
					}
				}
			}

			.flexbox-sidebar {
				flex-direction: column;

				.sidebar-search-engine {
					width: 100%;

					.input-service {
						input {
							text-align: center;
						}
					}
				}

				.sidebar-categories {
					width: 100%;
					margin-top: 20px;
				}
			}
		}
	}

	section.static-page-section-content {
		> * {
			margin-top: 50px;
		}

		.content-welcome {
			.welcome-inner {
				padding: 50px 0;
			}
		}

		.content-2-text {
			.text-inner {
				> * {
					& + * {
						margin-top: 50px;
					}
				}
			}
		}

		.content-3-text {
			.text-inner {
				> * {
					& + * {
						margin-top: 50px;
					}
				}
			}
		}

		.content-photo-text {
			.photo-text-inner {
				img {
					& + .static-text-wrapper {
						margin-top: 50px;
					}
				}

				.static-text-wrapper {
					& + img {
						margin-top: 50px;
					}
				}
			}
		}

		.content-accordion {
			.accordion {
				.accordion-item {
					.accordion-header {
						.accordion-button {
							font-size: 22px;

							span {
								&:after {
									top: 6px;
								}
							}
						}
					}
				}
			}
		}

		.content-tiles {
			.tiles-inner {
				margin-top: -20px;

				.inner-service {
					margin-top: 20px;
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-inner {
			.inner-flexbox {
				.flexbox-service {
					.service-name {
						font-size: 30px;
						line-height: 32px;
					}

					.service-description {
						margin-top: 15px;
					}

					.service-contact {
						margin-top: 15px;

						li {
							& + li {
								margin-top: 6px;
							}
						}
					}
				}
			}

			.inner-info {
				margin-top: 50px;
				flex-direction: column;

				.info-service {
					.service-icon {
						img, svg {
							height: 50px;
							width: 50px;
						}
					}

					.service-name {
						margin-top: 15px;
						line-height: 32px;
						font-size: 30px;
					}

					.service-description {
						font-size: 18px;
						line-height: 20px;
						margin-top: 8px;
					}

					& + .info-service {
						margin-top: 50px;
						margin-left: 0;
					}
				}
			}
		}
	}

	section.section-footer {
		.footer-inner {
			.inner-service {
				width: 100%;

				&:nth-child(2) {
					margin-top: 30px !important;
					border: none !important;
				}

				.service-list {
					li {
						&:not(:last-child) {
							margin-bottom: 4px;
						}

						&.heading {
							font-size: 20px;
							margin-bottom: 10px;

							a, button {
								text-align: center;
								font-size: 20px;
								position: relative;
								padding: 0 30px;
								pointer-events: auto;

								img {
									display: block;
								}
							}
						}

						&.collapse {
							display: none;

							&.show {
								display: block;
							}
						}

						a {
							font-size: 14px;
							font-family: 'tt_commonsregular';
						}
					}

					& + .service-list {
						border-top: 1px solid #ccc;
						margin-top: 15px;
						padding-top: 15px;
					}

					&:last-child {
						margin-bottom: 15px;
					}
				}

				& + .inner-service {
					border-top: 1px solid #ccc;
					margin-top: 0;
					padding-top: 15px;
				}
			}
		}

		.footer-info {
			display: block;
			text-align: center;

			.info-service {
				display: block;

				.qed {
					margin-left: 0;
					margin-top: 10px;
				}

				& + .info-service {
					margin-top: 35px;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	section.static-page-section-content {
		.content-icons {
			.icons-inner {
				.inner-service {
					.service-avatar {
						img, svg {
							height: 35px;
							width: 35px;
						}
					}

					.service-name {
						font-size: 17px;
						line-height: 19px;
					}
				}
			}
		}
	}

	section.wykladowcy-section-content {
		.content-inner {
			margin-top: 25px;

			.inner-service {
				.service-avatar {
					img, svg {
						height: 35px;
						width: 35px;
					}
				}

				.service-name {
					font-size: 17px;
					line-height: 19px;
				}
			}
		}
	}
}

@media (max-width: 420px) {
	section.section-offer {
		.offer-inner {
			.inner-service {
				width: 100%;
			}
		}
	}
}

@media (max-width: 390px) {
	nav.section-header {
		.header-top {
			.top-logo {
				img {
					height: 65px;
				}
			}

			.top-menu {
				.menu-contact {
					li {
						.btn {
							&.btn-small {
								padding-left: 12px;
								padding-right: 12px;
							}
						}

						.nav-button {
							padding: 0;
							margin: 0;
							border: 2.1px solid @color1;
							background: none;
							border-radius: 50%;
							height: 39.5px;
							width: 39.5px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							outline: none;
							transition: all 0.5s;

							svg, img {
								height: 20px;
								width: 20px;
								fill: #333;
								transition: all 0.5s;
							}

							&:hover, &:focus {
								background: @color1;

								svg, img {
									fill: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
}